import useAPIClient from "../../core/services/API/hooks/useAPIClient";
import useCacheQuery, {CacheQueryOpts, CacheQueryOutput} from "../../core/services/query/hooks/useCacheQuery";
import Booking from "../types/Booking";

type Output = Booking;

export default function useBookingByPrebookingInternalIdGet (
    prebookingInternalId: string,
    options?: CacheQueryOpts<Output>
): CacheQueryOutput<Output> {
    const client = useAPIClient();
    return useCacheQuery<Output>(
        ["booking", "by-prebooking-internal-id", prebookingInternalId],
        () => {
            return client.get<Output>(`bookings/by-prebooking-internal-id/${prebookingInternalId}`);
        },
        options
    );
}
