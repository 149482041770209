import {useCallback} from "react";
import useAPIClient from "../../core/services/API/hooks/useAPIClient";
import PaymentUrl from "../types/PaymentUrl";

interface PaymentParams {
    prebookingId: string,
    urlOk: string;
    urlKo: string;
    language: string;
}

export default function useSubscriptionPaymentUrlGet () : (params: PaymentParams) => Promise<PaymentUrl> {
    const client = useAPIClient();

    return useCallback((params: PaymentParams) => {
        const requestParams = { ...params, language: params.language.toUpperCase() };
        return client.get<PaymentUrl>("bookings/get-iframe-subscription", requestParams);
    }, [client]);
}
