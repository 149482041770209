const translations = {
    mainNav: {
        language: "Language",
        shoppingBasket: "Carrello",
    },
    footer: {
        legalNotice: "Avviso legale",
        privacyPolicy: "Politica sulla riservatezza",
        cookiesPolicy: "Politica sui cookie",
        complaintChannel: "Canale di Segnalazione"
    },
    bookingForm: {
        event: "Evento",
        checkIn: "Dal",
        checkOut: "Al",
        guest: "persona",
        guests: "persone",
        addDate: "Add date",
        addGuest: "Add",
        numberOfRooms: "Camere",
        adults: "Adulti",
        children: "Bambini",
        roomNumber: "Camera {{number}}"
    },
    cityEventMainCard: {
        hotels: "Alberghi",
        transferService: "Servizio di transferimento",
        from: "Dal",
        to: "Al",
    },
    hotelMapCard: {
        minNightsRequired: "Questo hotel richiede un minimo di {{nights}} notti",
    },
    hotelDetail: {
        amenities: "Punti salienti della proprietà",
        viewMoreAmenities: "view more amenities",
        informationAndExtras: "Informazioni ed extras",
        location: "Location",
        cancellationPolicy: "Politica di cancellazione",
        modifyDates: "Modifica le date",
        addRoom: "Aggiundere camera",
        from: "From:",
        to: "To:",
        capacity: "Capacità:",
        total: "Prezzo",
        roomAdded: "Added room",
        roomNotAvailable: "Camera non disponibile",
        roomNotAvailableAt: "Non c'è disponibilità per queste notti: {{dates}}",
        roomAvailableWithOverbooking: "Camera con giorni in attesa di conferma",
        roomAvailableWithOverbookingAt: "Prenotazione con giorni in attesa di conferma. Il nostro team controllerà la disponibilità per queste notti e ti ricontatterà il prima possibile: {{dates}}",
        roomAvailableWithOverbookingOnRequest: "SU RICHIESTA",
        roomAvailableWithOverbookingConfirm: "Fare clic su conferma per continuare",
        hotelNotAvailable: "Non puoi aggiungere camere di hotel diversi",
        addRoomErrorTitle: "An error ocurred.",
        addRoomError: "An error occurred and the room could not be added to the basket",
        goToCheckout: "Vai alla cassa",
        details: "Details",
    },
    common: {
        actions: {
            add: "Aggiungi",
            accept: "Accetta",
            cancel: "Annulla",
            edit: "Modifica",
            delete: "Elimina",
            submit: "Invia",
            save: "Salva",
            upload_file: "Carica file",
            search: "Cerca",
            show_more_filters: "Mostra più filtri",
            hide_more_filters: "Nascondi altri filtri",
            clear: "Pulito",
            back: "Ritorno",
            goHome: "Vai a casa",
            copy: "Copia",
            confirm: "Conferma",
        },
        labels: {
            required_field: "Campo obbligatorio",
            filters: "Filtri",
            none: "Nessuno",
            all: "Tutti",
            creation_date: "Data di creazione",
            error: "Errore",
            administration: "Amministrazione",
            actions: "Azioni",
            selected_items: "{{count}} elementi selezionati",
            per_page: "Per pagina",
            sort_by: "Ordina per",
            total: "Totale",
            language: "Lingua",
        },
        feedback: {
            saved_data: "Dati salvati con successo",
            no_data: "Nessun dato",
            form_not_valid: "Ci sono errori nel modulo",
            field_required: "Il campo è obbligatorio",
            field_min: "Il valore del campo non può essere inferiore a {{min}}",
            field_max: "Il valore del campo non può essere maggiore di {{max}}",
            field_min_length:
                "La lunghezza del campo non può essere inferiore a {{length}}",
            field_max_length:
                "La lunghezza del campo non può essere maggiore di {{length}}",
            field_valid_email: "{{value}} non è un'email valida",
            field_valid_iban: "{{value}} non è un IBAN corretto",
            unexpected_error: "Si è verificato un errore imprevisto",
        },
    },

    auth: {
        actions: {
            sign_in: "Accedi",
            logout: "Esci",
            reset_password: "Reimposta password",
        },
        fields: {
            email: "e-mail",
            password: "Password",
            new_password: "Nuova password",
            confirm_new_password: "Conferma Nuova password",
        },
        labels: {
            forgot_password: "Hai dimenticato la password?",
            forgot_password_subheading:
                "Inserisci la tua email per reimpostare la password",
        },
        feedback: {
            unauthorized: "La sessione non è valida",
            passwords_does_not_match: "Le password non corrispondono",
        },
    },

    error404: {
        labels: {
            heading:
                "Spiacenti, non riusciamo a trovare la pagina che stai cercando.",
            subheading:
                "Potrebbe esserci un errore di ortografia nell'URL che hai inserito o la pagina che stai cercando non esiste più.",
        },
    },

    home: {
        title: "Inizio",
    },
    cart: {
        title: "Carrello",
    },
    checkout: {
        title: "Dettagli della prenotazione",
        continueShopping: "Continua a fare acquisti",
        noItemsInTheCart: "Non ci sono articoli nel carrello",
        total: "Totale",
        total_without_taxes: "Totale senza tasse",
        total_taxes: "Tasse",
        discount: "Sconto",
        total_to_pay: "Totale da pagare",
        confirm_booking: "Conferma prenotazione",
        clear_basket: "Cancella cestino",
        total_nights: "Notti",
        nights: "notti",
        night_of: "Notte di",
        from: "Da",
        to: "A",
        delete: "Elimina",
        book_price_detail: "Dettagli prezzo libro",
        errorWithPaymentItWillBeRefund: "Si è verificato un errore nel processo di pagamento. Se il pagamento è stato effettuato, verrà restituito per intero nel più breve tempo possibile. Contatta un amministratore se l'errore continua a verificarsi."
    },
    booking: {
        title: "Conferma e pagamento",
        personalInformation: "Informazioni personali",
        name: "Nome",
        surname: "Cognomi",
        email: "Email",
        telephone: "Telefono",
        invalidTelephone: "Per favore inserisci UN numero di telefono valido",
        roomsWithOverbookingDisclaimer: "Quando si finaliza la prenotazione, Le contattaremo al più presto poiché la prenotazione sarà in attesa di conferma per i giorni seguenti:",
        roomsToBePaidAtTheHotelDisclaimer: "Questa prenotazione contiene camere a pagare direttamente all´albergo e si debe fornire i dati della carta bancaria per política dell´albergo.",
        paymentRequiredDisclaimer: "Someone from the Love Events team will contact you shortly to make the payment.",
        creditCardInformation: "Informazioni sulla carta di credito",
        creditCardHolder: "Proprietario della carta di credito",
        creditCardNumber: "Numero di carta di credito",
        invalidCreditCardNumber: "Inserisci un numero di carta di credito valido",
        expiration: "Espirazione",
        invalidCreditCardExpiration: "La data di scadenza della carta di credito deve essere successiva alla data della prenotazione",
        roomDetails: "Detagli camere",
        room: "Camera",
        guests: "Ospiti",
        guestN: "Ospite {{number}}",
        child: "Bambino",
        checkInTime: "Ora di arrivo all´albergo",
        iDontKnow: "Non lo so",
        comments: "Commenti",
        acceptTerms: "Ho letto e accetto ",
        termsAndConditions: "i termini e le condizioni",
        submit: "Finalizza la prenotazione",
        formContainsErrors: "Il modulo contiene errori. Si prega di controllarli.",
        errorWhenMakingTheBookingHeading: "Errore durante la prenotazione",
        errorWhenMakingTheBookingBody: "Si è verificato un errore durante la prenotazione. Per favore riprova. Se il problema persiste, contattaci.",
        prebookingHasExpiredError: "La prenotazione anticipata è scaduta dopo 15 minuti dall'inizio del processo senza completarlo. Si prega di riavviare il processo di prenotazione",
        bookingDetails: "Booking details",
        invoiceNameAndSurname: "Nome completo",
        invoicePassportCif: "Numero di passaporto",
        invoiceAddress: "Indirizzo fiscale",
        payment: "Pagamento",
        scheduledPaymentDisclaimer: "A first payment of {{amount}} will be made. On the day {{nextPaymentDate}} the rest of the amount ({{nextPaymentAmount}}) will be automatically charged.",
    },
    bookOk: {
        yourBooking: "La tua prenotazione",
        bookingConfirmed: "La tua prenotazione è stata confermata",
        roomsWithOverbookingDisclaimer: "Una volta effettuata la prenotazione ti contatteremo il prima possibile perché questa prenotazione deve essere approvata",
        rememberPay: "Ricorda, quando arrivi in hotel, dovrai pagare:  ",
        confirmationNumber: "Numero di conferma",
        nameAndSurnames: "Nome e cognomi",
        email: "E-mail di contatto",
        telephone: "Telefono di contatto",
        hotelDetails: "Dettagli hotel",
        event: "Evento",
        city: "Città",
        hotel: "Albergo",
        address: "Indirizzo",
        payMethod: "Metodo di pagamento",
        payOnHotel: "Paga in hotel",
        roomDetails: "Dettagli camera",
        roomType: "Tipo di camera",
        occupancy: "Occupazione",
        arrivalHour: "Ora di arrivo",
        guest: "Ospite",
        total: "Totale"
    }
};

export default translations;
