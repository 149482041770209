import useAPIClient from "../../core/services/API/hooks/useAPIClient";
import useDataSave, { DataSaveType } from "../../core/services/query/hooks/useDataSave";

interface UpdatePrebookingInput{
    id: string;
    name?: string;
    surnames?: string;
    telephone?: string;
    dialCode?: string;
    iso2?: string;
    email?: string;
}

export default function usePrebookingUpdate (): DataSaveType<UpdatePrebookingInput> {
    const client = useAPIClient();
    return useDataSave<UpdatePrebookingInput>(
        ({ id, ...rest }: UpdatePrebookingInput) => client.put(`prebookings/${id}`, rest),
    );
}
