import React, {FC} from "react";
import { formatNumber } from "../../modules/common/utils/number";
import useTranslate from "../../modules/core/languages/hooks/useTranslate";
import PaymentUrl from "../../modules/payment/types/PaymentUrl";
import {Alert} from "../../modules/ui/shared/Alert/Alert";
import NcModal from "../../modules/ui/shared/NcModal/NcModal";

interface TpvModalProps {
    isOpen: boolean,
    onClose: () => void;
    paymentUrl: PaymentUrl;
}

const TpvModal: FC<TpvModalProps> = ({
    isOpen,
    onClose,
    paymentUrl
}) => {
    const t = useTranslate();

    const renderDisclaimer = () => {
        if (!paymentUrl.nextPaymentAmount || !paymentUrl.nextPaymentDate) {
            return null;
        }

        return (
            <Alert type="info" closable={false} className="mb-2">
                <span dangerouslySetInnerHTML={{ __html: t(
                    "booking.scheduledPaymentDisclaimer",
                    {
                        amount: `${formatNumber(paymentUrl.amount)} ${paymentUrl.currencySymbol}`,
                        nextPaymentDate: paymentUrl.nextPaymentDate,
                        nextPaymentAmount: `${formatNumber(paymentUrl.nextPaymentAmount)} ${paymentUrl.currencySymbol}`
                    }
                )}} />
            </Alert>
        );
    }

    const renderContent = (
        <>
            {renderDisclaimer()}
            <iframe
                title={t("booking.payment")}
                src={paymentUrl.iframeUrl}
                sandbox="allow-top-navigation allow-scripts allow-same-origin allow-forms"
                className="w-full h-[600px]"
            />
        </>
    );

    return (
        <NcModal
            contentExtraClass="max-w-screen-xxl"
            modalTitle={t("booking.payment")}
            renderContent={() => renderContent}
            renderTrigger={() => null}
            isOpenProp={isOpen}
            onCloseModal={onClose}
        />
    )
}

export default TpvModal;
