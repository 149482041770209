import useAPIClient from "../../core/services/API/hooks/useAPIClient";
import useCacheQuery, {CacheQueryOpts, CacheQueryOutput} from "../../core/services/query/hooks/useCacheQuery";
import Prebooking from "../types/Prebooking";

type Output = Prebooking;

export default function useGetPrebooking (
    prebookingInternalId: any,
    options?: CacheQueryOpts
): CacheQueryOutput<Output> {
    const client = useAPIClient();
    return useCacheQuery<Output>(
        ["prebooking-by-internal-id", prebookingInternalId],
        () => {
            if (null === prebookingInternalId) {
                throw new Error('Error getting prebooking ID');
            }

            return client.get<any>(`prebooking-by-internal-id/` + prebookingInternalId);
        },
        options
    );
}