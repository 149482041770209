const translations = {
    mainNav: {
        language: "Language",
        shoppingBasket: "Basket",
    },
    footer: {
        legalNotice: "Legal notice",
        privacyPolicy: "Privacy policy",
        cookiesPolicy: "Cookies policy",
        complaintChannel: "Complaint Channel"
    },
    bookingForm: {
        event: "Event",
        checkIn: "Check In",
        checkOut: "Check Out",
        guest: "Guest",
        guests: "Guests",
        addDate: "Add date",
        addGuest: "Add",
        numberOfRooms: "Rooms",
        adults: "Adults",
        children: "Children",
        roomNumber: "Room {{number}}"
    },
    cityEventMainCard: {
        hotels: "Hotels",
        transferService: "Transfer service",
        from: "From",
        to: "Until",
    },
    hotelMapCard: {
        minNightsRequired: "This hotel requires a minimum of {{nights}} nights",
    },
    hotelDetail: {
        amenities: "Amenities",
        viewMoreAmenities: "View more amenities",
        informationAndExtras: "Information and Extras",
        location: "Location",
        cancellationPolicy: "Cancellation Policy",
        modifyDates: "Modify dates",
        addRoom: "Add room",
        from: "From:",
        to: "To:",
        capacity: "Capacity:",
        total: "Total",
        roomAdded: "Room added",
        roomNotAvailable: "Room not available",
        roomNotAvailableAt: "There is no availability for these nights: {{dates}}",
        roomAvailableWithOverbooking: "Room with days pending confirmation",
        roomAvailableWithOverbookingAt: "Booking with days pending confirmation. Our team will check the availability on these nights and get back to you as soon as possible: {{dates}}",
        roomAvailableWithOverbookingOnRequest: "ON REQUEST",
        roomAvailableWithOverbookingConfirm: "Please click confirm to continue.",
        hotelNotAvailable: "You cannot add rooms from different hotels",
        addRoomErrorTitle: "An error ocurred.",
        addRoomError: "An error occurred and the room could not be added to the basket",
        goToCheckout: "Go to checkout",
        details: "Details",
    },
    common: {
        actions: {
            add: "Add",
            accept: "Accept",
            cancel: "Cancel",
            edit: "Edit",
            delete: "Remove",
            submit: "Send",
            save: "Save",
            upload_file: "Upload file",
            search: "Search",
            show_more_filters: "Show more filters",
            hide_more_filters: "Hide more filters",
            clear: "Clear",
            back: "Back",
            goHome: "Go Home",
            copy: "Copy",
            confirm: "Confirm",
        },
        labels: {
            required_field: "Required field",
            filters: "Filters",
            none: "None",
            all: "All",
            creation_date: "Creation date",
            error: "Error",
            administration: "Administration",
            actions: "Actions",
            selected_items: "{{count}} Selected Items",
            per_page: "Per page",
            sort_by: "Sort by",
            total: "Total",
            language: "Language",
        },
        feedback: {
            saved_data: "Data saved successfully",
            no_data: "No data",
            form_not_valid: "There are errors in the form",
            field_required: "The field is required",
            field_min: "The field value cannot be less than {{min}}",
            field_max: "The field value cannot be greater than {{max}}",
            field_min_length:
                "The field length cannot be less than {{length}}",
            field_max_length:
                "The field length cannot be greater than {{length}}",
            field_valid_email: "{{value}} is not a valid email",
            field_valid_iban: "{{value}} is not a valid IBAN",
            unexpected_error: "An unexpected error has occurred",
        },
    },

    auth: {
        actions: {
            sign_in: "Sign In",
            logout: "Log Out",
            reset_password: "Restore password",
        },
        fields: {
            email: "Email address",
            password: "Pasword",
            new_password: "New password",
            confirm_new_password: "Confirm new password",
        },
        labels: {
            forgot_password: "Have you forgotten the password?",
            forgot_password_subheading:
                "Enter your email to reset your password",
        },
        feedback: {
            unauthorized: "The session is invalid",
            passwords_does_not_match: "Passwords do not match",
        },
    },

    error404: {
        labels: {
            heading:
                "Sorry, we can't seem to find the page you're looking for.",
            subheading:
                "There may be a misspelling in the URL you entered, or the page you're looking for no longer exists.",
        },
    },

    home: {
        title: "Inicio",
    },
    cart: {
        title: "Carrito",
    },
    checkout: {
        title: "Booking details",
        continueShopping: "Continue shopping",
        noItemsInTheCart: "There are no items in the cart",
        total: "Total",
        total_without_taxes: "Total without taxes",
        total_taxes: "Taxes",
        discount: "Discount",
        total_to_pay: "Total to pay",
        confirm_booking: "Confirm booking",
        clear_basket: "Clear basket",
        total_nights: "Nights",
        nights: "nights",
        night_of: "Night of",
        from: "From",
        to: "To",
        delete: "Delete",
        book_price_detail: "Book price detail",
        errorWithPaymentItWillBeRefund: "An error has occurred during the payment process. If the payment has been processed, it will be refunded in full as soon as possible. Please contact an administrator if the error persists."
    },
    booking: {
        title: "Confirmation and payment",
        personalInformation: "Personal information",
        name: "Name",
        surname: "Surname",
        email: "Email",
        telephone: "Telephone",
        invalidTelephone: "Please enter a valid telephone number",
        roomsWithOverbookingDisclaimer: "Once the reservation is done we will contact you as soon as possible because this booking has to be approved:",
        roomsToBePaidAtTheHotelDisclaimer: "This reservation contains rooms to be paid at the hotel and the credit card details are required to guarantee the room with the hotel.",
        paymentRequiredDisclaimer: "Someone from the Love Events team will contact you shortly to make the payment.",
        creditCardInformation: "Credit card information",
        creditCardHolder: "Credit card holder",
        creditCardNumber: "Credit card number",
        invalidCreditCardNumber: "Please enter a valid credit card number",
        expiration: "Expiration",
        invalidCreditCardExpiration: "The expiration date of the credit card must be after the date of the reservation",
        roomDetails: "Room details",
        room: "Room",
        guests: "Guests",
        guestN: "Guest {{number}}",
        child: "Child",
        checkInTime: "Check in time",
        iDontKnow: "I don't know",
        comments: "Comments",
        acceptTerms: "I have read and I accept ",
        termsAndConditions: "Terms & Conditions",
        submit: "Finalise the booking",
        formContainsErrors: "The form contains errors. Please check them.",
        errorWhenMakingTheBookingHeading: "Error when making the reservation",
        errorWhenMakingTheBookingBody: "An error has occurred while making the reservation. Please try again. If the problem persists, contact us.",
        prebookingHasExpiredError: "The prebooking it has expired after 15 minutes from starting the booking without finishing it. Please, start again the booking process",
        bookingDetails: "Booking details",
        invoiceNameAndSurname: "Full name",
        invoicePassportCif: "Passport number",
        invoiceAddress: "Fiscal address",
        payment: "Payment",
        scheduledPaymentDisclaimer: "A first payment of {{amount}} will be made. On the day {{nextPaymentDate}} the rest of the amount ({{nextPaymentAmount}}) will be automatically charged.",
    },
    bookOk: {
        yourBooking: "Your booking",
        bookingConfirmed: "Your booking has been confirmed",
        roomsWithOverbookingDisclaimer: "Once the reservation is done we will contact you as soon as possible because this booking has to be approved",
        rememberPay: "Remember, when you arrive to the hotel, you will need to pay:  ",
        confirmationNumber: "Confirmation number",
        nameAndSurnames: "Name and surnames",
        email: "Contact email",
        telephone: "Contact telephone",
        hotelDetails: "Hotel details",
        event: "Event",
        city: "City",
        hotel: "Hotel",
        address: "Address",
        payMethod: "Method payment",
        payOnHotel: "Pay at the hotel",
        roomDetails: "Room Details",
        roomType: "Room type",
        occupancy: "Occupancy",
        arrivalHour: "Arrival hour",
        guest: "Guest",
        total: "Total"
    }
};

export default translations;
