import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import PagePaymentProcessed from "./PagePaymentProcessed";
import { Page } from "./types";
import ScrollToTop from "./ScrollToTop";
import PageHome from "pages/PageHome";
import Page404 from "pages/Page404/Page404";
import PageCityEvent from "./PageCityEvent";
import LegalNotice from "./PoliciesPage/LegalNotice";
import PrivacyPolicy from "./PoliciesPage/PrivacyPolicy";
import CookiesPolicy from "./PoliciesPage/CookiesPolicy";
import PageHotel from "./PageHotel";
import PageCheckout from "./PageCheckout";
import PageBooking from "./PageBooking";
import PageBookOk from "./PageBookOk";
import PageSubscriptionPaymentProcessed from "./PageSubscriptionPaymentProcessed";

export const pages: Page[] = [
    { path: "/", exact: true, component: PageHome },
    { path: "/event-city/:id", exact: true, component: PageCityEvent },
    { path: "/event-city/:eventCityId/:eventHotelId/hotel/:hotelId", exact: true, component: PageHotel },
    { path: "/legal-notice", exact: true, component: LegalNotice },
    { path: "/privacy-policy", exact: true, component: PrivacyPolicy },
    { path: "/cookies-policy", exact: true, component: CookiesPolicy },
    { path: "/checkout", exact: true, component: PageCheckout },
    { path: "/book", exact: true, component: PageBooking },
    { path: "/book-ok/:bookingInternalId", exact: true, component: PageBookOk },
    { path: "/payment-processed", exact: true, component: PagePaymentProcessed },
    { path: "/subscription-payment-processed", exact: true, component: PageSubscriptionPaymentProcessed },
];

const MyRoutes = () => {
  return (
    <BrowserRouter>
      <ScrollToTop />

      <Routes>
        {pages.map(({ component, path }) => {
          const Component = component;
          return <Route key={path} element={<Component />} path={path} />;
        })}
        <Route element={<Page404 />} />
      </Routes>
    </BrowserRouter>
  );
};

export default MyRoutes;
