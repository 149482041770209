import React, {useEffect} from 'react';
import {useNavigate, useSearchParams} from "react-router-dom";
import useBookingByPrebookingInternalIdGet from "../../modules/booking/hooks/useBookingByPrebookingInternalIdGet";
import useBookingSearchContext from "../../modules/booking/hooks/useBookingSearchContext";
import Loader from "../../modules/ui/shared/Loader/Loader";

const PagePaymentProcessed = () => {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const { setPrebookingId } = useBookingSearchContext();

    const prebookingInternalId = searchParams.get('r') || "";
    const { data: booking, isError } = useBookingByPrebookingInternalIdGet(
        prebookingInternalId,
        {
            onSuccess: (booking) => {
                setPrebookingId(null);
                navigate(`/book-ok/${booking.internal_id}`)
            }
        }
    );

    useEffect(() => {
        if (isError) {
            setPrebookingId(null);
            navigate(`/checkout?error=1`)
        }
    }, [isError]);

    return (
        <div className="flex items-center justify-center w-full h-screen">
            <Loader/>
        </div>
    );
};

export default PagePaymentProcessed;
