export const handlePaymentRequiredDisclaimer = (
    paymentRequiredDisclaimer: string | null,
    defaultText: string
): {
    showingPaymentRequiredDisclaimerText: boolean,
    paymentRequiredDisclaimerText: string
} => {
    let showingPaymentRequiredDisclaimerText = false;
    let paymentRequiredDisclaimerText: string = defaultText;

    if (paymentRequiredDisclaimer !== null && paymentRequiredDisclaimer !== "") {
        showingPaymentRequiredDisclaimerText = true;
        paymentRequiredDisclaimerText = paymentRequiredDisclaimer;
    }

    return {
        showingPaymentRequiredDisclaimerText,
        paymentRequiredDisclaimerText
    }
}