import useAPIClient from "../../core/services/API/hooks/useAPIClient";
import useDataSave, { DataSaveType } from "../../core/services/query/hooks/useDataSave";

interface UpdatePrebookingRoomInput{
    id: number | string;
    checkIn: string;
    comments: string;
    guests: {
        name: string;
        surname: string;
    }[];
}

export default function usePrebookingRoomUpdate (): DataSaveType<UpdatePrebookingRoomInput> {
    const client = useAPIClient();
    return useDataSave<UpdatePrebookingRoomInput>(
        ({ id, ...rest }: UpdatePrebookingRoomInput) => client.put(`prebooking-rooms/${id}`, rest),
    );
}
