import React, { FC } from "react";
import { Link } from "react-router-dom";
import SaleOffBadge from "modules/ui/components/SaleOffBadge/SaleOffBadge";
import Badge from "modules/ui/shared/Badge/Badge";
import HotelGallery from "./HotelGallery";
import HotelDetail from "../../../modules/hotels/types/HotelDetail";
import HotelImage from "../../../modules/hotels/types/HotelImage";
import {StarIcon} from "@heroicons/react/24/solid";
import useTranslate from "../../../modules/core/languages/hooks/useTranslate";
import {formatNumber} from "../../../modules/common/utils/number";

export interface HotelGridCardProps {
    hotelDetail: HotelDetail;
    cityEventId: number;
}

const HotelGridCard: FC<HotelGridCardProps> = ({
    hotelDetail,
    cityEventId
}) => {
    const t = useTranslate();

    let redirect = false;
    let onClickUrl: string = `/event-city/${cityEventId}/${hotelDetail.event_hotel_id}/hotel/${hotelDetail.hotel_id}`;
    if (hotelDetail.external_url !== null) {
        redirect = true;
        onClickUrl = hotelDetail.external_url;
    }
    let showDiscountCode = false;
    if (redirect && null !== hotelDetail.discount_code) {
        showDiscountCode = true;
    }

    const renderSliderGallery = (hotel:HotelDetail) => {
        return (
            <div className="relative flex-shrink-0 w-full md:w-72 ">
                <HotelGallery
                    ratioClass="aspect-w-6 aspect-h-5"
                    galleryImgs={
                        hotelDetail.images.map((image:HotelImage) => (
                            (process.env.REACT_APP_API_HOTEL_STORAGE as string) + hotel.hotel_id + "/" + image.file
                        ))
                    }
                    uniqueID={`StayCardH_${hotel.hotel_id}`}
                    href={onClickUrl}
                    redirect={redirect}
                    linkActive={hotelDetail.available_book}
                />
                {hotel.high_demand && <SaleOffBadge className="absolute left-3 top-3" />}
            </div>
        );
    };

    const renderSuggestedRoom = (hotel:HotelDetail) => {
        return (
            <>
                <div>
                    {hotel.suggestedRoom?.type}
                </div>
                <div>
                   {[...Array(hotel.suggestedRoom?.adults || 0)].map((value, index) =>
                       <i className="las la-user la-2x" key={index}></i>
                   )}
                    {[...Array(hotel.suggestedRoom?.children || 0)].map((value, index) =>
                        <i className="text-2xl las la-user" key={index}></i>
                    )}
                </div>
            </>
        );
    };

    const minimumNightsRequires = (hotel:HotelDetail) => {
        return (
            <>
                <Badge name={
                    t("hotelMapCard.minNightsRequired", {"noches" : hotelDetail.min_nights})
                } color="red"/>
            </>
        );
    };

    const renderDiscountCode = () => {
        if (showDiscountCode) {
            return (
                <>
                    <Badge name={
                        t("hotelMapCard.discountCode", {"code" : hotelDetail.discount_code})
                    } color="red"/>
                </>
            );
        } else {
            return "";
        }
    };

    const renderContent = (hotel:HotelDetail) => {
        return (
            <div className="flex-grow p-3 sm:p-5 flex flex-col">
                <div className="space-y-2">
                    <div className="flex items-end">
                        {[...Array(hotel.stars)].map((value, index) => <StarIcon className="w-[18px] h-[18px] text-orange-500" key={index} />)}
                        <div className="flex items-end" style={{marginLeft:'auto' }}>
                            {renderDiscountCode()}
                        </div>
                    </div>

                    <div className="flex items-center space-x-2">
                        <h2 className="text-lg font-medium capitalize">
                            <span className="line-clamp-1">{hotel.name}</span>
                        </h2>
                    </div>
                    <p className="text-sm text-neutral-500 dark:text-neutral-400"><i className="las la-map-marker text-lg ml"></i> {hotel.address}</p>
                </div>
                <div className="hidden sm:block w-14 border-b border-neutral-100 dark:border-neutral-800 my-4"></div>
                {hotelDetail.available_book ?
                    renderSuggestedRoom(hotel)
                    :
                    minimumNightsRequires(hotel)
                }
                <div className="w-14 border-b border-neutral-100 dark:border-neutral-800 my-4"></div>
                <div className="flex justify-between items-end">
                    {hotel.event_distance && (
                    <Badge name={hotel.event_distance} color="green" />
                    )}
                    {hotelDetail.available_book && (
                        <span className="text-base font-semibold text-secondary-500">
                            {hotelDetail.price_prefix && (
                                <span className="text-sm text-neutral-500 dark:text-neutral-400 font-normal">
                                    {hotelDetail.price_prefix}&nbsp;
                                </span>
                            )}
                            {formatNumber(hotel.suggestedRoom?.total_price)} {' '}
                            <span dangerouslySetInnerHTML={{ __html:hotel.suggestedRoom?.currency}} />
                            <span className="text-sm text-neutral-500 dark:text-neutral-400 font-normal">
                                /for {hotel.suggestedRoom?.total_days} days
                            </span>
                        </span>
                    )}
                </div>
            </div>
        );
    };

    const renderOnClickUrl = () => {
        if (!redirect) {
            return <Link to={onClickUrl} className="absolute inset-0"></Link>
        } else {
            return <a href={onClickUrl} rel="noopener noreferrer" className="absolute inset-0"></a>
        }
    };

    return (
        <div
            className={`nc-StayCardH group relative bg-white dark:bg-neutral-900 border border-neutral-100 dark:border-neutral-800 rounded-2xl overflow-hidden hover:shadow-xl transition-shadow will-change-transform`}
            data-nc-id="StayCardH"
        >
            {hotelDetail.available_book && (
                renderOnClickUrl()
            )}

            {hotelDetail ?
            <div className="grid grid-cols-1 md:flex md:flex-row ">
                {renderSliderGallery(hotelDetail)}
                {renderContent(hotelDetail)}
            </div>
            : null }
        </div>
    );
};

export default HotelGridCard;
